import '/assets/styles/pages/brands.scss';

import { useState, useEffect, useRef } from 'react'
import kebabCase from 'lodash/kebabCase'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'

// Partials
import Placeholder from '/views/partials/placeholder'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';

// Services
import siteServices from '/services/site'

// Shared Endpoints
const getFeaturedBrands = () => {
	return siteServices.getFeaturedBrands(false);
}
const getBrands = () => {
	return siteServices.getAllBrands();
}

const placeholderFeaturedBrands = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
const placeholderBrands = [false, false, false, false, false, false, false, false, false, false];

const Brands = () => {
	const _mounted = useRef(false);

	const [featuredBrands, setFeaturedBrands] = useState(placeholderFeaturedBrands);
	const [brands, setBrands] = useState(placeholderBrands);

	useEffect(() => {
		_mounted.current = true;

		getFeaturedBrands().then((payload) => {
			if(_mounted.current) {
				setFeaturedBrands(payload);
			}
		}).catch(() => {
			if(_mounted.current) {
				setFeaturedBrands(false);
			}
		})

		getBrands().then((payload) => {
			if(_mounted.current) {
				setBrands(payload);
			}
		}).catch(() => {
			if(_mounted.current) {
				setBrands(false);
			}
		})

		return () => {
			_mounted.current = false;
		}
	}, [])

	return (
		<main className="page brands">
			<Breadcrumbs data={[{name: `Markalar`}]} />
			
			<div className="wrapper">
				{featuredBrands &&
					<div className="section brands-featured tabs-container">
						<div className="tabs-labels">
							<div className="labels-innerwrap">
								<div className="labels-item">
									<h2 className="item-btn single active">Öne Çıkan Markalar</h2>
								</div>
							</div>
						</div>
						<ul className="section tabs-items featured-list">
							{featuredBrands.map((brand, nth) => (
								<li className="list-brand" key={`featured-${nth}`}>
									{brand ?
										<Link
											href="brand"
											title={brand.name}
											params={{ id: brand.id, slug: kebabCase(brand.name) }}
											className="brand-link">
											<div className="brand-imagewrap">
												<Img
													src={brand.image}
													alt={brand.name}
													className="brand-image" />
											</div>
										</Link>
										:
										<div className="brand-link">
											<Placeholder className="brand-imagewrap" />
										</div>
									}
								</li>
							))}
						</ul>
					</div>
				}
				{(brands && brands.length > 0) &&
					<div className="section brands-alphabeticlist tabs-container">
						<div className="tabs-labels">
							<div className="labels-innerwrap">
								<div className="labels-item">
									<h2 className="item-btn single active">Tüm Markalar</h2>
								</div>
							</div>
						</div>
						<ul className="section tabs-items alphabeticlist-groups">
							{brands.map((group, nth) => (
								<li className="groups-group" key={`group-${group?.key ?? nth}`}>
									{group ?
										<>
											<strong className="group-title">{group.key === '0' ? '0-9' : group.key}</strong>
											<ul className="group-list">
												{group.brands.map((brand, nth) => (
													<li className="list-brand" key={`subgroup-${nth}`}>
														<Link
															href="brand"
															params={{ id: brand.id, slug: kebabCase(brand.name) }}
															className="link brand-link">
															{brand.name}
														</Link>
													</li>
												))}
											</ul>
										</>
										:
										<>
											<Placeholder className="group-title" />
											<ul className="group-list">
												<Placeholder className="list-brand" />
												<Placeholder className="list-brand" />
												<Placeholder className="list-brand" />
												<Placeholder className="list-brand" />
												<Placeholder className="list-brand" />
												<Placeholder className="list-brand" />
											</ul>
										</>
									}
								</li>
							))}
						</ul>
					</div>
				}
			</div>
		</main>
	)
}

export default Brands;